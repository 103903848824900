export const environment = {
	production: true,
	api: {
		core: {
			uri: 'https://dipvtel.call-metrics.com:9443',
			path: '/api/v1'
		},
		ws: {
			uri: 'ws://{{HOST_IP}}:8081'
		}
	},
  stripe: {
    key: "pk_test_51MExjqAHXo4nJ6TuOMsdZZjrobRFdyhcG044kPgY2xH7ZeNDtclGWTCnzOuuL77WR8xoRyeHO3qtwr6CdvwRZwXn00CFeVtSyh"
  }
};
