<p-blockUI [blocked]="blockContent"></p-blockUI>

<div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
  <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
    <div class="col-12 mt-5 xl:mt-0 text-center">
      <a class="header-brand cm_brand" [routerLink]="['/auth/login']" href="javascript:void(0)">
        <img src="assets/images/logo-callmetrics.svg" alt="eCMS logo" class="mb-5" style="height:60px;">
      </a>
    </div>
    <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
      <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
        <div class="text-center mb-5">
          <div class="text-900 text-3xl font-medium mb-3">Activate Account</div>
        </div>
        <div class="w-full md:w-10 mx-auto">
          <button type="button" pButton pRipple label="Activate" class="w-full p-3 text-xl mt-5" (click)="onActivate()"></button>
          <label class="block text-400 text-lg font-medium mt-6" >
            Forget it,
            <a [routerLink]="['/auth/login']" href="javascript:void(0)" class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)">Send me Back</a>
            to the Sign in screen.
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

